import {Audio} from "./audio.js";

export class AudioDriver {
  init() {
    // Load the audio settings from local storage.
    const isEnabledValue = window.localStorage.getItem('audioDriver.isEnabled');
    if (isEnabledValue) {
      this.#isEnabled = JSON.parse(isEnabledValue);
    }

    // Score click sound.
    if (this.#scoreAudio[0]) {
      this.#scoreAudio[0].init();
      this.#scoreAudio[0].loadSoundAudioContext(['/click6a.mp3'], 'click');
      this.#scoreAudio[0].changeSoundAudioContext('click', false);
    }

    if (this.#scoreAudio[1]) {
      this.#scoreAudio[1].init();
      this.#scoreAudio[1].loadSoundAudioContext(['/click6b.mp3'], 'click');
      this.#scoreAudio[1].changeSoundAudioContext('click', false);
    }

    if (this.#scoreAudio[2]) {
      this.#scoreAudio[2].init();
      this.#scoreAudio[2].loadSoundAudioContext(['/click6c.mp3'], 'click');
      this.#scoreAudio[2].changeSoundAudioContext('click', false);
    }

    // Spinner bearing sound.
    if (this.#spinningAudio) {
      this.#spinningAudio.init();
      this.#spinningAudio.loadSoundAudioContext(['/spinner.mp3'], 'spinner');
      this.#spinningAudio.changeSoundAudioContext('spinner', false);
    }

    this.#updateAudioState();
  }

  playScoreSound() {
    if (this.#isEnabled && this.#scoreAudio.length > 0) {
      // Play a random score sound.
      const index = Math.floor(Math.random() * this.#scoreAudio.length);
      if (this.#scoreAudio[index]) {
        this.#scoreAudio[index].playSoundAudioContext('click');
      }
    }
  }

  /**
   * Plays the spinner bearing sound if the sound setting is enabled.
   */
  playSpinningSound() {
    if (this.#isEnabled && this.#spinningAudio) {
      // this.#spinningAudio.playSoundAudioContext('spinner');
      // this.#isSpinningSoundPlaying = true;
    }
  }

  /**
   * Stops the bearing sound if it is currently playing.
   */
  stopSpinningSound() {
    if (this.#spinningAudio) {
      this.#spinningAudio.stopSoundAudioContext();
      this.#isSpinningSoundPlaying = false;
    }
  }

  /**
   * Updates the audio volume based on enable state.
   */
  #updateAudioState() {
    const volume = this.#isEnabled ? 1 : 0;

    if (this.#spinningAudio) {
      this.#spinningAudio.gain.gain.value = this.#isEnabled ? this.#spinningSoundVolume : volume;
    }

    if (this.#scoreAudio.length) {
      for (let i = 0; i < this.#scoreAudio.length; i++) {
        this.#scoreAudio[i].gain.gain.value = this.#isEnabled ? this.#scoreSoundVolume : volume;
      }
    }
  }

  /**
   * Toggles the sound state between enabled and disabled.
   */
  toggleSound() {
    this.#isEnabled = !this.#isEnabled;

    this.#updateAudioState();

    // Save the audio settings to local storage.
    window.localStorage.setItem('audioDriver.isEnabled', JSON.stringify(this.#isEnabled));
  }

  /**
   * Returns whether the audio is enabled.
   * @return {boolean}
   */
  isEnabled() {
    return this.#isEnabled;
  }

  isSpinningSoundPlaying() {
    return this.#isSpinningSoundPlaying;
  }

  /** @param {number} v */
  updateSpinningVolume(v) {
    if (!this.#isEnabled || !this.#spinningAudio) {
      return;
    }

    // Compute the volume based on the angular velocity and set it.
    const volume = this.#getVolumeFromAngularVelocity(v, this.#maxAngularVelocity);
    this.#spinningAudio.setVolume(volume);
  }

  /** @param {number} angularVelocity
   * @param {number} [maxAngularVelocity=5]
   * @return {number} */
  #getVolumeFromAngularVelocity(angularVelocity, maxAngularVelocity) {
    return Math.min(Math.abs(angularVelocity) / maxAngularVelocity, 1);
  }

  //region Properties

  /** Represents the maximum angular velocity used for spinning sound volume normalization. */
  #maxAngularVelocity = 0.0333;
  #isEnabled = true;
  #scoreAudio = [new Audio(), new Audio(), new Audio()];
  #spinningAudio = new Audio();
  #isSpinningSoundPlaying = false;
  #scoreSoundVolume = 0.35;
  #spinningSoundVolume = 0.05;

  //endregion
}
